.base {
  position: relative;
  overflow: hidden; }

.input {
  position: absolute;
  top: -20px;
  left: -20px;

  &[disabled] + .label {
    opacity: 0.5; } }

.label {
  display: flex;
  align-items: center;
  height: 21px;
  overflow: hidden; }

.box {
  width: 19px;
  height: 19px;
  margin-right: 7px;
  border: 1px solid var(--grayish-brown);
  border-radius: 10px;
  background: #FFF;
  line-height: 17px;
  text-align: center; }

.boxChecked {
  composes: box;

  .dot {
    display: block; } }

.dot {
  display: none;
  width: 11px;
  height: 11px;
  margin: 3px;
  background: var(--grayish-brown);
  border-radius: 5px; }

.blue {
  composes: base;

  .boxChecked {
    border-color: var(--blue);

    .dot {
      background: var(--blue); } } }
