.container {
  display: flex;
  height: 45px;
  align-items: center; }

.deleting {
  opacity: 0.5; }

.dragging {
  opacity: 0.1; }

.adding {
  padding-left: 35px; }

.videoLink {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center; }

.videoLinkLabel {
  min-width: 0;
  flex: 1; }

.slideIcon {
  position: relative;
  width: 22px;
  height: 18px;
  margin-top: 4px;
  border: 1px solid var(--deep-blue);
  border-radius: 3px; }

.slideIconActive {
  composes: slideIcon;
  border-color: var(--coral-red);

  .slideBadge {
    background: var(--coral-red); } }

.slideBadge {
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 18px;
  padding: 1px 3px;
  background: var(--deep-blue);
  border: 2px solid #FFF;
  border-radius: 10px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #FFF; }

.slideBadgeActive {
  composes: slideBadge;
  background: var(--blue); }
