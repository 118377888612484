.base {
  display: block;
  width: 100%;
  min-height: 40px;
  margin: 0;
  padding: 7px 15px;
  border-radius: 4px;
  border: 1px solid var(--deep-blue);
  font-size: 16px;
  line-height: 24px;
  resize: none;
  color: var(--grayish-brown);
  -webkit-appearance: none; }

.error {
  composes: base;
  border: 1px solid var(--coral-red); }

.transparent {
  composes: base;
  min-height: 38px;
  border: none; }

.teleprompt {
  width: 100%;
  margin: 0;
  padding: 0 30px;
  border: none;
  background: none;
  resize: none;
  font-size: inherit;
  line-height: inherit;
  text-align: center;
  color: inherit; }

.autoHeight {
  overflow: hidden; }

.block {
  display: block; }

.disabled {
  cursor: not-allowed; }
