.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 50px 30px;
  text-align: center; }

.paragraph {
  max-width: 400px;
  margin: 0 auto; }
