.container {
  min-height: 40px; }

.form {
  display: flex;
  width: 230px; }

.formTextField {
  flex: 1; }

.loader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 230px;
  min-height: 40px; }

.coupon {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 0 40px 0 15px;
  border: 1px dashed var(--dusty-gray);

  @media (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    align-items: flex-start; } }

.couponId {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid var(--mercury);

  @media (max-width: 767px) {
    margin: 0;
    padding: 0;
    border: none; } }

.btnCouponRemove {
  position: absolute;
  top: -3px;
  right: -3px;

  @media (max-width: 767px) {
    top: 50%;
    margin-top: -22px; } }
