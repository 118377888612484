.container {
  position: relative;
  height: 100%;
  overflow: hidden; }

.canvas {
  display: block;
  margin: 0 auto; }

.selected {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px 5px;
  background: #FFF;
  border: 1px solid var(--dark-blue);
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-blue); }
