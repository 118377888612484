.container {
  display: flex;
  padding: 35px 0;

  + .container {
    border-top: 1px solid var(--mercury); }

  @media (max-width: 767px) {
    display: block; } }

.header {
  display: flex;
  align-items: center; }

.status {
  display: inline-block;
  vertical-align: 3px;
  margin-left: 10px;
  padding: 4px 20px;
  border-radius: 30px;
  background: var(--green); }

.button {
  margin-left: auto;

  @media (max-width: 767px) {
    margin-top: 30px; } }

@media (max-width: 767px) {
  .header {
    flex-direction: column;
    align-items: flex-start; }

  .status {
    order: 1;
    margin: 0 0 10px; }

  .name {
    order: 2; } }
