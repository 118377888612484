.container {
  padding: 25px;

  label {
    span {
      font-weight: 500;
      font-size: 18px; } } }

.subTitle {
  margin-top: 0.5em;
  margin-bottom: 1em;
  display: block; }

.messageInToolTip {
  a {
    display: list-item;
    list-style-type: none;
    color: var(--green); } }
