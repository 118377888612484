.buttons {
  display: flex;
  padding-top: 2em;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  background: none;
  color: #FFF;

  a, div, button {
    color: #FFF;
    margin-left: 1em;
    margin-right: 1em; } }
