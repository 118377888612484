.viewer {
  margin: 45px 45px 0;
  padding-bottom: 20px; }

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 45px 0 0;
  padding: 70px 50px;
  background: var(--ice);
  text-align: center; }
