.list {
  margin-top: 20px; }

.RemovePitchFromFeed {
  color: red;
  padding: 0;
  border: none;
  background: none;
  margin-left: 10px;
  font-size: 12px;
  margin-top: 5px; }

.RemovePitchIcon {
  border-radius: 50%;
  border: 1.5px solid red;
  padding: 2px; }

.SubmitButton {
  margin-top: 1em; }
