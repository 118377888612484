// normalize.css

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

main {
  display: block; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted; }

b,strong {
  font-weight: bolder; }

code,kbd,samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

button,input,optgroup,select,textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,input {
  overflow: visible; }

button,select {
  text-transform: none; }

button,[type="button"],[type="reset"],[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,[type="button"]:-moz-focusring,[type="reset"]:-moz-focusring,[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

// global styles

@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i&display=swap');

 :root {
  --mercury: #e2e2e2;
  --dusty-gray: #979797;
  --silver-gray: #7b93a8;
  --lux-gray: #f5f8fc;
  --lux-gray-border: #e1e2e9;
  --lux-gray-shadow: #e0e2e4;
  --grayish-brown: #1e2f3e;
  --grayish-brown-rgb: 74, 74, 74;
  --blue: #0174db;
  --deep-blue: #1e2f3e;
  --deep-blue-rgb: 30, 47, 62;
  --deep-blue-light: #ebf5ff;
  --dark-blue: #14212c;
  --ice: #f6f8fa;
  --coral-red: #ff4040;
  --coral-red-rgb: 255, 64, 64;
  --mango: #db8700;
  --green: #66e8d2;
  --light-gray: #ecedf0;
  --nav-bar-color: #1e2f3e;
  --nav-bar-text: #fff;
  --nav-bar-size: 65px;
  --white: #fff;
  --neon-blue: #06deba; }

* {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

body {
  position: relative;
  margin: 0;
  padding: 0;
  background: var(--ice);
  cursor: default;
  font: 14px/1.42 Rubik, sans-serif;
  color: var(--grayish-brown);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.white {
    background: #FFF; } }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

a {
  text-decoration: none;
  color: var(--deep-blue); }

.js-focus-visible :focus {
  outline: none; }

#root {
  height: 100%; }
