.container {
  width: 35px;
  height: 42px;
  padding-left: 10px;
  font-size: 1px;
  line-height: 42px; }

.disabled {
  opacity: 0.5; }

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 14px;

  &:before {
    content: " ";
    display: block;
    height: 2px;
    margin-bottom: 2px;
    border: solid var(--dusty-gray);
    border-width: 1px 0; }

  &:after {
    content: " ";
    display: block;
    border-top: 1px solid var(--dusty-gray); } }
