.overwrite {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(023, 023, 023, 0.3); }

.buildButton {
  margin-top: 1em;
  padding: 1em;
  width: 30em;
  height: 7em;
  z-index: 10;
  margin-left: -160px;
  align-items: center;
  text-align: center;

  button, a {
    margin-top: 0.5em; } }

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding-bottom: 50px;
  background: linear-gradient(rgba(20, 33 ,44, 1), rgba(20, 33, 44, 0)); }

.full {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  padding: 100px 15px 10px;
  background: linear-gradient(rgba(20, 33, 44, 0) 20%, rgba(20, 33, 44, 1)); }

.btnPlay {
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: 1;
  margin: -47px 0 0 -47px;

  @media (max-width: 767px) {
    margin: -32px 0 0 -32px; } }
