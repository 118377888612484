.menu {
  flex: 1;
  display: flex;
  margin: 0 0 0 60px;
  padding: 0;
  list-style: none; }

.menuItem {
  position: relative;

  &:hover {
    box-shadow: inset 0 -3px 0 var(--green); }

  + .menuItem {
    margin-left: 30px; }

  + .menuItemConnections,
  + .menuItemProfile {
    margin-left: auto; } }

.menuItemAnalytics {
  composes: menuItem;

  @media (max-width: 870px) {
    display: none; } }

.menuItemWithDropdown {
  composes: menuItem;

  &:hover {
    box-shadow: none;

    .menuDropdown {
      display: block; } } }

.menuItemConnections,
.menuItemProfile {
  composes: menuItem;
  margin-right: 20px; }

.menuDropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: -20px;
  margin: 0;
  padding: 0;
  list-style: none;
  background: var(--nav-bar-color);
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  overflow: hidden;
  white-space: nowrap; }

.menuDropdownRight {
  composes: menuDropdown;
  left: auto;
  right: -20px; }

.menuDropdownItem {
  margin: 0 20px;

  + .menuDropdownItem {
    border-top: 1px solid var(--light-gray); } }

.connectionsBadge {
  position: absolute;
  top: 18px;
  left: 100%;
  margin-left: 4px; }

.initials {
  width: 33px;
  height: 33px;
  border-radius: 18px;
  font-weight: 500;
  font-size: 17px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
  background: var(--white);
  color: var(--deep-blue); }

.NavActive {
  box-shadow: inset 0 -3px 0 var(--green); }

.SubNavActive {
  box-shadow: inset 0 -3px 0 var(--green); }

.upgradeButton {
  a {
    color: var(--deep-blue); }
  margin-left: auto;
  margin-right: 20px;
  margin-top: 10px; }
