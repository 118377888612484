.saving {
  position: fixed;
  z-index: 99;
  left: 50%;
  top: 75px;
  padding: 3px 10px;
  border-radius: 20px;
  background: rgba(var(--grayish-brown-rgb), 0.1);
  opacity: 0;
  transform: translate(-50%, 0);
  transition: opacity 0.2s; }

.savingActive {
  composes: saving;
  opacity: 1; }

.well {
  position: relative;
  padding: 25px 30px 30px 30px;
  background: #FFF;
  border-radius: 10px;

  + .well {
    margin-top: 25px; } }

.row {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr;
  margin: 25px 0; }

.btnRemove {
  position: absolute;
  top: 0;
  right: 0; }
