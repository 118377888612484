.TemplateTopic {
  padding: 10px;
  display: block;
  @media (max-height: 900px) {
    max-width: 200px; }
  max-width: 210px;
  margin-left: 1em; }

.subject {
  margin-top: 10px; }

.question {
  margin-top: 7px;
  line-height: 1.1;
  color: var(--silver-gray); }

.selectedTopic {
  border: 2px solid var(--neon-blue);
  background-color: rgba(var(--grayish-brown-rgb), 0.4);
  color: #000; }
