.container {
  margin: 20px;
  background: #FFF;
  border-radius: 6px;
  overflow: hidden;
  text-align: center; }

.header {
  padding: 16px 20px;
  background-image: linear-gradient(80deg, #0174db 0%, #34aed6 55%, #66e8d2 100%); }

.body {
  padding: 20px;
  line-height: 24px; }
