.hero {
  position: relative;
  background: var(--deep-blue);
  color: #FFF;

  &:before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: solid transparent;
    border-width: 0 0 150px 100vw;
    border-bottom-color: var(--ice);

    @media (max-width: 767px) {
      border-bottom-width: 50px; } } }

.heroInner {
  position: relative;
  max-width: 1005px;
  margin: 0 auto;
  padding: 150px 30px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 60px; } }

.heroText {
  max-width: 400px; }

.heroImage {
  position: absolute;
  top: 10%;
  left: 62%;
  right: 0;
  height: 110%;

  img {
    display: block;
    height: 100%; }

  @media (max-width: 767px) {
    display: none; } }

.article {
  max-width: 1005px;
  margin: 0 auto;
  padding: 100px 30px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px; } }
