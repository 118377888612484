.container {
  display: flex;
  align-items: center; }

.chart {
  position: relative;
  margin-right: 35px;

  svg {
    display: block; } }

.interactions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  text-align: center; }

.list {
  white-space: nowrap; }

.percentage {
  min-width: 50px;
  margin-right: 10px;
  text-align: center; }

@media (max-width: 450px) {
  .chart {
    margin-right: 25px; } }

@media (max-width: 420px) {
  .container {
    flex-direction: column;
    align-items: flex-start; }

  .chart {
    margin: 0 0 30px; } }
