.container {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0; } }

.nextToTitle {
  margin-left: 5px; }

.warning {
  margin-top: 1em;
  margin-bottom: 1em; }
