.container {
  position: relative;

  + .container {
    margin-top: 15px;

    &:before {
      content: " ";
      position: absolute;
      top: -15px;
      left: 10px;
      height: 15px;
      border-left: 1px dashed var(--dusty-gray); } } }

.index {
  float: left;
  width: 21px;
  height: 21px;
  margin-right: 10px;
  border: 1px solid var(--dusty-gray);
  border-radius: 11px;
  line-height: 19px;
  text-align: center; }

.indexComplete {
  composes: index;
  border-color: var(--green);
  background: var(--green);

  + .index {
    display: none; } }
