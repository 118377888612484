.coupon {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--mercury);

  @media (max-width: 767px) {
    display: block;
    padding: 0;
    border: none; } }
