.base {
  display: inline-block;
  vertical-align: middle;
  height: 44px;
  margin: 0;
  padding: 11px 19px;
  border: 1px solid transparent;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;

  &.disabled {
    opacity: 0.25;
    pointer-events: none;
    cursor: default; }

  &:hover,
  &:active,
  &:global(.focus-visible) {
    opacity: 0.75; }

  .iconContainer {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    margin: -11px 10px -11px -5px;

    &.icon-offset-half {
      margin-right: 5px; }

    &.iconPositionAfter {
      float: right;
      margin-right: -5px;
      margin-left: 10px;

      &.icon-offset-half {
        margin-left: 5px; } } } }

.primary {
  composes: base;
  border-color: var(--blue);
  background: var(--blue);
  color: #FFF;

  &:hover,
  &:active,
  &:global(.focus-visible) {
    opacity: 0.8; }

  &.disabled {
    opacity: 1;
    color: #9b9b9b;
    border-color: #efefef;
    background: #efefef; } }

.primaryNarrow {
  composes: primary;
  height: 40px;
  padding-top: 9px;
  padding-bottom: 9px; }

.primarySmaller {
  composes: primary;
  height: 37px;
  padding-bottom: 8px;
  padding-top: 8px; }

.secondary {
  composes: base;
  border-color: var(--deep-blue);
  background: var(--deep-blue);
  color: #FFF;

  &:hover,
  &:active,
  &:global(.focus-visible) {
    opacity: 0.8; } }

.white {
  composes: base;
  border-color: #FFF;
  background: #FFF;
  color: var(--deep-blue); }

.red {
  composes: base;
  border-color: #ff4040;
  background: #ff4040;
  color: #FFF; }

.tooltipWhite {
  composes: white;
  height: 36px;
  padding: 7px 15px; }

.outline {
  composes: base;
  border-color: var(--deep-blue);
  color: var(--deep-blue);

  &.disabled {
    opacity: 1;
    background: none;
    border-color: #efefef;
    color: #efefef; } }

.outlineRed {
  composes: outline;
  border-color: var(--coral-red);
  color: var(--coral-red); }

.outlineNarrow {
  composes: outline;
  height: 40px;
  padding-top: 9px;
  padding-bottom: 9px; }

.segment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 28px;
  color: #FFF;

  &:hover,
  &:active,
  &:global(.focus-visible) {
    opacity: 0.75; }

  &.active {
    color: var(--green); } }

.segmentCollapse {
  composes: base;
  width: 100%;
  padding: 0;
  height: 30px;
  line-height: 28px;

  &.active {
    height: 42px;
    padding: 0 10px 0 20px; } }

.green {
  composes: base;
  color: var(--deep-blue);
  background: var(--green); }

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  background: none;
  cursor: pointer;
  font-size: 1px;
  line-height: 42px;
  text-align: center;

  &:hover,
  &:active,
  &:global(.focus-visible) {
    opacity: 0.8; }

  &.active {
    opacity: 1; }

  * {
    vertical-align: middle; }

  &.disabled {
    opacity: 0.3;
    cursor: default; } }

.iconSmall {
  composes: icon;
  width: 28px;
  height: 28px;
  line-height: 26px; }

.iconEditor {
  composes: icon;
  position: relative;

  svg {
    position: relative; }

  &.active {
    &:before {
      content: " ";
      position: absolute;
      top: 7px;
      right: 7px;
      bottom: 7px;
      left: 7px;
      border: 1px solid var(--deep-blue);
      background: none;
      border-radius: 4px; } } }

.iconWhite {
  composes: icon;
  color: #FFF; }

.iconBlack {
  composes: icon;
  color: var(--dark-blue); }

.iconPrimary {
  composes: icon;
  border-color: var(--deep-blue);
  background: var(--deep-blue); }

.iconOutline {
  composes: icon;
  border-color: var(--deep-blue); }

.iconFooter {
  composes: icon;
  width: auto;
  height: auto;
  border-radius: 50px;
  overflow: hidden;
  line-height: normal; }

.transparent {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;

  &:hover,
  &:active,
  &:global(.focus-visible) {
    opacity: 0.75; }

  &.active {
    opacity: 1;
    cursor: default; } }

.iconOnTop {
  composes: transparent;
  text-align: center;
  color: var(--deep-blue);

  &.disabled {
    pointer-events: none;
    filter: unquote('grayscale(100%)'); }

  .iconContainer {
    margin-bottom: 20px; } }

.iconOnTopRound {
  composes: iconOnTop;
  font-size: 12px;

  .iconContainer {
    width: 44px;
    height: 44px;
    margin: 0 auto 10px;
    border-radius: 22px;
    background: var(--deep-blue); }

  &.disabled {
    opacity: 0.4; } }

.iconOnTopRoundHighlight {
  composes: iconOnTopRound;

  .iconContainer {
    line-height: 42px;
    background-image: linear-gradient(225deg, #66e8d2, #0174db); } }

.iconOnTopRoundPrimary {
  composes: iconOnTopRound;
  font-size: 12px;

  .iconContainer {
    line-height: 42px;
    background: var(--blue); } }

.iconOnLeft {
  composes: transparent;
  display: flex;
  align-items: center;
  text-align: left;
  color: var(--deep-blue);

  &.disabled {
    pointer-events: none;
    filter: unquote('grayscale(100%)'); }

  .iconContainer {
    margin-right: 20px; } }

.tab {
  composes: base;
  position: relative;
  border: none;
  color: var(--grayish-brown);

  > span {
    display: inline-block;
    margin: -3px 0;
    padding: 3px 10px;
    border-radius: 7px; }

  &.active > span {
    background: var(--deep-blue-light); } }

.mobileNavbar {
  composes: transparent;
  padding: 0 12px;
  font-size: 17px;
  line-height: 44px;

  .iconContainer {
    font-size: 2px;
    margin-right: 5px;
    vertical-align: middle; } }

.feedAction {
  composes: iconOnTopRound;
  color: #FFF;

  .iconContainer {
    background: rgba(103, 110, 117, 0.45); }

  &.disabled {
    opacity: 0.5;
    cursor: default; }

  @media (max-width: 767px) {
    min-width: 44px;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    .iconContainer {
      width: 35px;
      height: 35px;
      margin-bottom: 5px;

      svg {
        width: 24px;
        height: 24px; } } } }

.feedActionPrimary {
  composes: feedAction;

  .iconContainer {
    background-image: linear-gradient(225deg, #66e8d2, #0174db); } }

.feedActionPreview {
  composes: feedAction;

  .iconContainer {
    background: rgba(103, 110, 117, 0.8); } }

.play {
  composes: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 94px;
  padding-left: 8px;
  border-radius: 47px;
  background: rgba(23, 23, 23, 0.7);

  @media (max-width: 767px) {
    width: 64px;
    height: 64px;
    padding-left: 6px;

    svg {
      width: 32px;
      height: 32px; } } }

.playDisabled {
  z-index: 1;
  composes: play;
  background: rgba(23, 23, 23, 0.2); }

.record {
  composes: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 74px;
  border-radius: 37px;
  background: var(--coral-red);

  &:before {
    content: " ";
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border: 2px solid #FFF;
    border-radius: 43px; }

  &.disabled {
    background: var(--mercury); } }

.recordStop {
  composes: record;

  &:before {
    border-color: var(--coral-red); } }

.tape {
  composes: iconOnTop;

  .iconContainer {
    width: 80px;
    height: 45px;
    margin-top: 2px;
    margin-bottom: 5px;
    background: var(--ice);
    box-shadow: inset 0 0 0 1px var(--light-gray);
    filter: grayscale(100%); }

  &.active {
    .iconContainer {
      filter: none; } } }

// offset

.offset-half-left {
  margin-left: 12px; }

.offset-half-right {
  margin-right: 12px; }

.offset-single-left {
  margin-left: 25px; }

.offset-single-right {
  margin-right: 25px; }

.offset-double-left {
  margin-left: 50px; }

.offset-double-right {
  margin-right: 50px; }

.offset-double-top {
  margin-top: 50px; }

// padding

.padding-half {
  padding-right: 9px;
  padding-left: 9px; }

.padding-double {
  padding-right: 39px;
  padding-left: 39px; }

// block

.block {
  display: block;
  width: 100%; }

// misc

.iconOnTop,
.iconOnLeft {
  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center; } }

.templateTopic {
  composes: outline;
  width: 205px;
  height: 45px;
  background-color: var(--lux-gray);
  border: 1px solid var(--lux-gray-border);
  box-shadow: 0 0 4px 0 var(--lux-gray-shadow); }
