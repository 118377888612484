.Title {
  background-color: var(--grayish-brown);
  color: var(--white);
  padding: 10px;
  min-height: 4em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;

  span {
    order: 1;
    align-self: center;
    margin-left: auto;
    margin-right: auto; }

  ul {
    order: 2;
    align-self: flex-end;
    margin-left: auto; } }

.Content {
  display: flex;
  flex-direction: row;
  justify-content: center; }

