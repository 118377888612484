.container {
  display: flex;
  height: 100%; }

.main {
  width: 50%;
  display: flex;
  overflow: auto;
  padding: 35px;
  background: #FFF;
  text-align: center; }

.mainInner {
  margin: auto; }

.intro {
  margin-bottom: 30px; }

.form {
  max-width: 320px;
  margin: 35px auto;
  text-align: left; }

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: auto;
  background-image: linear-gradient(143deg, #5533ef, #0174db 41%, #66e8d2 100%); }

.heroText {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 35px;
  margin: auto;
  color: #FFF; }

.heroTextFounder {
  composes: heroText;
  max-width: 645px; }

.heroTextInvestor {
  composes: heroText;
  max-width: 470px;

  h2 {
    margin-bottom: 5px; }

  p {
    line-height: 26px; } }

.accelerators {
  margin: auto 35px 35px;
  text-align: center; }

.acceleratorsLogos {
  margin-top: 10px;
  border-radius: 4px;

  img {
    max-width: 90px;
    max-height: 60px;
    margin: 10px;
    vertical-align: middle; } }

@media (max-width: 1023px) {
  .blockquote {
    padding: 35px; } }

@media (max-width: 767px) {
  .container {
    display: block; }

  .main {
    width: auto; }

  .hero {
    width: auto;

    &:before {
      display: none; } }

  .heroImage {
    display: none; } }
