.rightMenu {
  display: flex;
  align-items: center;
  margin-left: auto;
  list-style: none; }

.rightMenuItem + .rightMenuItem {
  margin-left: 10px; }

.rightMenuText {
  margin-right: 20px;

  b {
    font-weight: 500; } }
