.container {
  display: flex;
  justify-content: space-between;
  min-height: 81px;
  margin-bottom: 20px;
  padding: 20px 35px;
  background: var(--deep-blue-light);

  @media (max-width: 767px) {
    display: block; } }

.col {
  padding-right: 30px;
  white-space: nowrap;

  &:last-child {
    width: auto;
    padding-right: 0; }

  @media (max-width: 767px) {
    width: auto; }

  + .col {
    @media (max-width: 767px) {
      margin-top: 15px; } } }

.spacer {
  width: 25%; }

.updateLink {
  margin-left: 20px;

  @media (max-width: 767px) {
    display: block;
    margin: 10px 0 0; } }
