.container {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 74px;
  border-radius: 8px 0 0 8px;
  background: rgba(var(--deep-blue-rgb), 0.65);
  color: #FFF; }

.list {
  margin: 0;
  padding: 0 20px 15px;
  list-style-type: none; }

.duration {
  margin-left: 20px; }
