.base {
  margin: 0;
  text-transform: none; }

// variant

.h1 {
  composes: base;
  font-weight: 300;
  font-size: 48px;

  @media (max-width: 767px) {
    font-size: 36px; } }

.h2 {
  composes: base;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px; }

.h3 {
  composes: base;
  font-weight: 400;
  font-size: 22px; }

.large {
  composes: base;
  font-size: 18px; }

.button {
  composes: base;
  font-weight: 500;
  font-size: 16px; }

.sectionTitle {
  composes: base;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase; }

.sectionTitleSmall {
  composes: base;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase; }

.blockTitle {
  composes: base;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: -0.3px; }

.standardLarger {
  composes: base;
  font-weight: 400;
  font-size: 16px; }

.standard {
  composes: base;
  font-style: normal;
  font-weight: 400;
  font-size: 14px; }

.light {
  composes: base;
  font-weight: 300;
  font-size: 14px; }

.small {
  composes: base;
  font-weight: 400;
  font-size: 12px; }

.smallUpper {
  composes: base;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase; }

.smallest {
  composes: base;
  font-size: 10px;
  font-weight: 400; }

.icon {
  display: inline-block;
  font-size: 1px;

  img {
    vertical-align: middle; } }

.withIcon {
  display: flex;
  align-items: center; }

// color

.color-black {
  color: var(--grayish-brown); }

.color-gray {
  color: var(--dusty-gray); }

.color-white {
  color: white; }

.color-blue {
  color: var(--deep-blue); }

.color-light-blue {
  color: var(--blue); }

.color-red {
  color: var(--coral-red); }

.color-yellow {
  color: var(--mango); }

.color-green {
  color: var(--green); }

.color-any-bg {
  display: inline-block;
  vertical-align: middle;
  padding: 3px 8px;
  border-radius: 3px;
  color: #FFF; }

.color-blue-bg {
  composes: color-any-bg;
  background: var(--blue); }

.color-yellow-bg {
  composes: color-any-bg;
  background: var(--mango); }

.color-green-bg {
  composes: color-any-bg;
  background: #31CC9B; }

// opacity

.opacity-50 {
  opacity: 0.5; }

// offset

.offset-quarter-top {
  margin-top: 0.25em; }

.offset-quarter-bottom {
  margin-bottom: 0.25em; }

.offset-half-top {
  margin-top: 0.5em; }

.offset-half-bottom {
  margin-bottom: 0.5em; }

.offset-single-top {
  margin-top: 1em; }

.offset-single-bottom {
  margin-bottom: 1em; }

.offset-double-top {
  margin-top: 2em; }

.offset-double-bottom {
  margin-bottom: 2em; }

.offset-half-left {
  margin-left: 0.5em; }

.offset-half-right {
  margin-right: 0.5em; }

// weight

.weight-300 {
  font-weight: 300; }

.weight-400 {
  font-weight: 400; }

.weight-500 {
  font-weight: 500; }

// decoration

.decoration-underline {
  text-decoration: underline; }

// block

.block {
  display: block; }

// linksInheritColor

.linksInheritColor a {
  text-decoration: underline;
  color: inherit; }

// truncated

.truncated {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

// centered

.centered {
  text-align: center; }

// verticallyCentered

.verticallyCentered {
  vertical-align: middle; }

// nowrap

.nowrap {
  white-space: nowrap; }

// inline

.inline {
  display: inline; }

// uppercase

.uppercase {
  text-transform: uppercase; }

// italic

.italic {
  font-style: italic; }

// highlight

.highlight {
  background: linear-gradient(241deg, #66e8d2, #0174db);
  background-clip: text;
  -webkit-text-fill-color: transparent; }
