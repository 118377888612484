.container {
  min-height: 45vh;
  // padding: 70px
  font-size: 22px;

  @media (max-width: 767px) {
    margin: 0 35px;
    padding: 0;
    font-size: 16px; } }

.content {
  max-width: 1000px;
  margin: 0 auto;

  h3,
  p,
  ul,
  ol {
    margin: 0; }

  ul {}
  list-style: disc;
  padding-left: 40px;

  ol {
    list-style: decimal;
    padding-left: 40px; }

  h3 {
    font-size: 32px;
    font-weight: 300;

    @media (max-width: 767px) {
      font-size: 24px; } }

  b,
  strong {
    font-weight: 500; } }
